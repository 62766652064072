import React, { useEffect, useState } from 'react';
import axios from 'axios';

import SubTitle from '../../components/common/SubTitle';
import SubCard from '../../components/common/SubCard';
import { FlexDiv, WrapperDiv } from '../../components/home/BestSeller';
import { GridDiv } from '../Wallets';
import sortList from '../../const/SortList';
import CustomSelect from '../../components/common/CustomSelect';

const Accessories = () => {
    const [data, setData] = useState(Array);
    const [sort, setSort] = useState(sortList[0]);

    // useEffect(() => {
    //     data.length === 0 &&
    //         axios
    //             .get(`/api/goods/category?categoryId=3&page=0&sort=${sort.id}&size=3`)
    //             .then((res) => {
    //                 setData(res.data.dto.content);
    //             })
    //             .catch((e) => console.log(e));
    // }, [sort]);

    useEffect(() => {
        data.length === 0 &&
            axios
                .get(`${process.env.REACT_APP_DOMAIN}/api/goods/category.php?prod_gbn=C&page=0&sort=1&size=3`)
                .then((res) => {
                    setData(res.data.dto.content);
                })
                .catch((e) => console.log(e));
    }, []);
    return (
        <div style={{ position: 'relative', width: '100%', height: 'fit-content' }}>
            <SubTitle title="악세서리" />
            <WrapperDiv>
                {/*<FlexDiv>
                    <p>
                        악세서리&nbsp;|&nbsp;
                        <span>콤페토에서 전개하는 모든 악세서리 컬랙션</span>
                    </p>
                    <CustomSelect sort={sort} setSort={setSort} />
                </FlexDiv>*/}
                <GridDiv>
                    {data[0] &&
                        data[0].goods &&
                        data.map((v, i) => (
                            <SubCard
                                key={i}
                                link={'/accessories/' + v.goods.id}
                                img={v.goods.thumb_src}
                                price={v.goods.price}
                                title={v.goods.goodsName}
                            />
                        ))}
                </GridDiv>
            </WrapperDiv>
        </div>
    );
};

export default Accessories;
