import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import formPrice from '../../../../const/formPrice';

import { CommonBtn } from '../../../../components/payment/ShipInfo';
import {
    CouponInput,
    MainContents,
    CouponInputWrap,
    UnUsedCouponWrap,
    CouponCard,
    BetweenFlex,
    CouponTitle,
    PagenationDiv,
} from '../../../../components/user/UserComponent';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../components/common/Pagination';

const MyPageCoupon = () => {
    const navigate = useNavigate();
    let member = useSelector((state) => state.member);

    const [coupone, setCoupone] = useState('');
    const [page, setPage] = useState(0);
    const [data, setData] = useState(Array);
    const [pageble, setPageble] = useState(Array);

    let today = new Date();

    const saveCouponHandler = async () => {
        if ((!coupone)) 
        {
            alert("쿠폰번호를 등록해 주세요.");
            return false;
        }

        await axios
            .post(`${process.env.REACT_APP_DOMAIN}/api/member/coupon_save.php`, {
                c_serial: coupone,
                memberId: member.id,
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    alert('정상적으로 등록되었습니다.');
                    window.location.reload();
                }
                else
                {
                    alert('쿠폰번호가 휴효하지 않습니다. 다시 입력해 주세요.');
                }
            })
            .catch((e) => alert('다시 시도해주세요\n' + e));
    };

    useEffect(() => {
        data.length === 0 &&
            axios
                .get(`${process.env.REACT_APP_DOMAIN}/api/member/coupon.php?id=` + member.id + `&page=${page}&size=2`)
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto);
                        setPageble(res.data.pagination);
                    }
                })
                .catch((e) => alert('다시 시도해주세요.\n' + e));
    }, []);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_DOMAIN}/api/member/coupon.php?id=` + member.id + `&page=${page}&size=2`)
            .then((res) => {
                if (res.data.result === '0000') {
                    setData(res.data.dto);
                    setPageble(res.data.pagination);
                }
            })
            .catch((e) => console.log(e));
    }, [page]);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p className="title">이벤트 쿠폰</p>
                <img
                    className="mobile-visible"
                    alt="←"
                    src="/images/leftarrow.svg"
                    width={15}
                    height={15}
                    onClick={() => navigate('/mypage')}
                />
            </div>
            <MainContents>
                <CouponInputWrap>
                    <CouponInput
                        type={'text'}
                        value={coupone}
                        onChange={(e) => setCoupone(e.target.value)}
                        placeholder="‘-’ 없이 입력"
                    />
                    <small>시리얼 넘버 16자를 입력해 주세요.</small>
                </CouponInputWrap>
                <CommonBtn property="var(--main-color)" color="white" type="button" onClick={saveCouponHandler}  >
                    <p>등록하기</p>
                </CommonBtn>
                <UnUsedCouponWrap>
                    {data.length > 0 &&
                        data.map((v, i) => (
                            <CouponCard key={i}>
                                <BetweenFlex>
                                    <img
                                        alt="logo"
                                        src="/images/logo.svg"
                                        style={{ position: 'relative', width: '67px' }}
                                    />
                                    <p>
                                        {Math.ceil(
                                            Math.abs(today.getTime() - new Date(v.expirationDate).getTime()) /
                                                (1000 * 60 * 60 * 24)
                                        )}
                                        일 후 소멸
                                    </p>
                                </BetweenFlex>
                                <CouponTitle>
                                    <span>
                                        {v.discountRate > 0
                                            ? v.discountRate + '% '
                                            : formPrice(v.discountPrice) + '원 '}
                                        할인ㅣ{v.couponName}
                                    </span>
                                    <p style={{ marginTop: '5px' }}>{formPrice(v.minPrice)}원 이상 구매 시 사용 가능</p>
                                </CouponTitle>
                                <p style={{ position: 'absolute', bottom: '10px' }}>
                                    {v.startDate.replace('T', ' ')} ~ {v.expirationDate.replace('T', ' ')}
                                </p>
                            </CouponCard>
                        ))}
                </UnUsedCouponWrap>
                <Pagination totalPage={pageble.totalPages} curr={page} set={setPage} />
            </MainContents>
        </div>
    );
};

export default MyPageCoupon;
