import React from "react";

import { Link } from "react-router-dom";

import styled from "styled-components";
import formPrice from "../../const/formPrice";

const BestSeller = ({ data }) => {
  return (
    <WrapperDiv>
      <h5>베스트 셀러</h5>
      <GridDiv>
        {data.length > 0 &&
          data.slice(0, 3).map((v, i) => (
            <GridInnerDiv
              key={i}
              display={i === 2 ? "none" : "grid"}
              onClick={() =>
                (window.location.href = `/best-seller/${v.goods.id}`)
              }
            >
              <div className="img-wrap" style={{ marginBottom: "5px" }}>
                <img
                  alt="icon"
                  src={`${process.env.REACT_APP_DOMAIN}${v.goods.thumb_src}`}
                />
              </div>
              <p className="good-name" style={{ fontWeight: "500" }}>
                {v.goods.goodsName}
              </p>
              <p>₩ {formPrice(v.goods.price)}</p>
            </GridInnerDiv>
          ))}
      </GridDiv>
      <Link to={"/best-seller"}>
        <button>
          <p>
            VIEW ALL
            {/* <img
              alt="arrow"
              src="/images/all_arrow.svg"
              style={{ margin: "0 0 0 4px" }}
            /> */}
          </p>
        </button>
      </Link>
    </WrapperDiv>
  );
};

export default BestSeller;

export const WrapperDiv = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  padding: 90px 26.7%;

  button {
    position: relative;
    display: block;
    text-align: right;
    margin-left: auto;
    width: fit-content;
  }
  @media screen and (max-width: 1400px) {
    padding: 80px 12%;
  }
  @media screen and (max-width: 700px) {
    padding: 50px var(--wrapper-padding) 40px;

    h5 {
      font-weight: 600;
    }
  }
`;

export const GridDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  padding: 40px 0;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr;

    padding: 20px 0;
  }
`;

export const GridInnerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .img-wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 128%;
    margin-bottom: 20px;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    left: 0;
    top: 0;
  }

  p {
    font-size: var(--font-size-sm);
    line-height: 1.3em;
  }

  cursor: pointer;

  @media screen and (max-width: 700px) {
    display: ${(props) => props.display};
    img {
      margin-bottom: 10px;
    }
    p {
      font-size: var(--font-size-p);
      line-height: 1.5em;
    }
  }
`;

export const FlexDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: var(--font-size-sm);
  }

  span {
    font-weight: 500;
  }

  @media screen and (max-width: 700px) {
    p {
      font-size: var(--font-size-xs);
    }

    flex-wrap: wrap;
    margin-bottom: 10px;
  }
`;
