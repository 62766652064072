import React from "react";

import { Link } from "react-router-dom";

import styled from "styled-components";

const HomeVideo = () => {
  return (
    <VideoDiv>
      <Video autoPlay muted loop playsInline>
        <source src="/videos/main.mp4" type="video/mp4" />
      </Video>
      <MobileVideo autoPlay muted loop playsInline>
        <source src="/videos/main_mo.mp4" type="video/mp4" />
      </MobileVideo>
      <TextDiv>
        <div>
          <h5>Our Love for Black</h5>
          <p>
            All of our products are
            <br className="mobile-visible" /> composed of the color black.
          </p>
          <Link to={"/about"}>
            <button>Our Story</button>
          </Link>
        </div>
      </TextDiv>
    </VideoDiv>
  );
};

export default HomeVideo;

const VideoDiv = styled.div`
  position: relative;

  width: 100%;
  height: fit-content;

  color: white;

  text-align: center;

  display: grid;
  align-items: center;

  p {
    padding: 20px 0;
  }

  button {
    position: relative;
    width: 181px;
    height: 45px;
    line-height: 45px;
    background-color: white;
    color: var(--main-color);
    border-radius: 3px;
    font-size: var(--font-size-p);
  }

  @media screen and (max-width: 700px) {
    button {
      width: 250px;
      margin-top: 30px;
      font-weight: 600;
      display: inline-block;
    }
  }
`;

const Video = styled.video`
  position: relative;
  width: 100%;
  height: 509px;

  object-fit: cover;
  z-index: 0;

  @media screen and (max-width: 750px) {
    display: none;
  }
`;

const MobileVideo = styled.video`
  position: relative;
  width: 100%;
  height: 400px;

  object-fit: cover;

  @media screen and (min-width: 751px) {
    display: none;
  }
`;

const TextDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  margin-top: 10px;

  h5 {
    font-weight: bold;
    margin-bottom: -5px;
  }

  display: grid;
  align-items: center;

  @media screen and (max-width: 700px) {
    top: 30px;

    p {
      font-weight: 600;
    }

    margin-top: 0;
  }
`;
