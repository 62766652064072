import React from "react";
import { Outlet, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";

import { insertGoods, initialGoods } from "../../store/cartSlice";

import { StyleButton } from "./SubDetail";



const AddcartBuyBtn = ({ data, count }) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  let cartList = useSelector((state) => state.cart);



  const addCartList = async () => {
    dispatch(
      insertGoods({
        id: data.id,
        goodsName: data.goodsName,
        thumb_src: data.thumb_src,
        price: data.price,
        count: count,
      })
    );

    alert("장바구니에 추가 되었습니다.");
    navigate('/payment/order-info');
  };

  return (
    <StyleButton  onClick={addCartList}>
      구매하기
    </StyleButton>
  );
};

export default AddcartBuyBtn;
