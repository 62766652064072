import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useScript from "../../../hooks/useScript";
import {
  decreasePrice,
  useCouponName,
  useCouponPrice,
} from "../../../store/PaymentPriceSlice";

import {
  CheckTerms,
  TermsWrap,
} from "../../../components/login/CheckCondition";

import CartPrice from "../../../components/cart/CartPrice";

import {
  AddressGrid,
  CheckBox,
  CommonBtn,
  FlexButton,
  InputWrap,
  MainInner,
  PaymentInput,
  PaymentWrap,
  RadioInner,
  RadioInput,
  RadioWrap,
  TitleFlex,
} from "../../../components/payment/ShipInfo";

const PayMethod = () => {
  let member = useSelector((state) => state.member);
  let cart = useSelector((state) => state.cart);
  let price = useSelector((state) => state.price);
  let dispatch = useDispatch();

  const [data, setData] = useState(Array);
  const [method, setMethod] = useState("card");
  const [couponList, setCouponList] = useState(Array);

  const navigate = useNavigate();

  const [checked, setChecked] = useState({
    age: false,
    terms: false,
    privacy: false,
  });

  const checkAll = (e) => {
    e.target.checked
      ? setChecked({
          age: true,
          terms: true,
          privacy: true,
        })
      : setChecked({
          age: false,
          terms: false,
          privacy: false,
        });
  };

  const [usePoint, setUsePoint] = useState(0);
  const [coupon, setCoupon] = useState(0);

  useEffect(() => {
    data.length === 0 &&
      axios
        .get(`${process.env.REACT_APP_DOMAIN}/api/payment-id.php`)
        .then((res) => {
          setData(res.data);
        })
        .catch((e) => console.log(e));
  }, [data]);

  useEffect(() => {
    couponList.length === 0 &&
      axios
        .get(`${process.env.REACT_APP_DOMAIN}/api/member/coupon_list?memId=` + member.id)
        .then((res) => {
          if (res.data.result === "0000") {
            setCouponList(res.data.dto.couponDtos);
          }
        })
        .catch((e) => console.log(e));
  }, [couponList]);

  const useCouponChangePrice = (e) => {
    let value = JSON.parse(e.target.value);
    let discountPrice = 0;

    setCoupon(value.id);

    if (value.price > 0) {
      dispatch(decreasePrice(value.price));
      discountPrice = -value.price;
    } else if (value.rate > 0) {
      dispatch(decreasePrice(price.price * (value.rate / 100)));
      discountPrice = -(price.price * (value.rate / 100));
    } else {
      dispatch(decreasePrice(0));
      discountPrice = 0;
    }

    dispatch(useCouponName(value.name));
    dispatch(useCouponPrice(discountPrice));
  };

  useScript("https://pay.nicepay.co.kr/v1/js/");

  const serverAuth = async () => {
    console.log(cart[0].goodsName);
    let goodsName =
      cart.length > 1
        ? `${cart[0].goodsName}외 ${cart.length - 1}`
        : cart[0].goodsName;

    const cartList = cart.map((v) => {
      return v.id;
    });

    console.log(cartList);

    await axios
      .post(`${process.env.REACT_APP_DOMAIN}/api/order_save.php`, {
        memberId: sessionStorage.getItem("memberId"),
        addressId: sessionStorage.getItem("addressId"),
        request: sessionStorage.getItem("request"),
        goodsId: cart.map((v) => {
          return v.id;
        }),
        amount: cart.map((v) => {
          return v.count;
        }),
      })
      .then((res) => 
        {
        if (res.data.result === "0000") 
          {
          // eslint-disable-next-line
          AUTHNICE.requestPay({
            // clientId: data.clientId,
            clientId: 'R2_92646f63b28c4244a294157e93337343',
            method: method,
            orderId: data.orderId,
            //amount: price.total,
            amount: 1000,
            goodsName: goodsName,
            // returnUrl: process.env.PUBLIC_URL + '/api/payment-serverAuth',
            returnUrl:
              //'http://localhost:3000' +
              "https://www.competoseoul.com" +
              "/api/payment-serverAuth.php?memId=" +
              member.id +
              "?couponId=" +
              coupon +
              "&orderId=" +
              res.data.dto.id,
            fnError: async function (result) {
              await axios
                .delete(`${process.env.REACT_APP_DOMAIN}/api/order_view.php?orderId=` + res.data.dto.id)
                .then((res) => {
                  if (res.data.result === "0000") {
                    alert(result.errorMsg + " 000 ");
                  }
                })
                .catch((e) => console.log(e));
            },
            skinType: "dark",
          });
        } else {
          alert("다시 시도해주세요.");
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <PaymentWrap>
      <p className="title">결제수단을 선택해 주세요</p>
      <form>
        <MainInner>
          <InputWrap>
            <TitleFlex>
              <label>보유 포인트: {member.point}P</label>
            </TitleFlex>
            <AddressGrid>
              <PaymentInput
                id="address"
                type={"number"}
                value={usePoint}
                disabled={member.point < 100 ? true : false}
                onChange={(e) => setUsePoint(e.target.value)}
                backgroundcolor={member.point < 100 ? "#EBEBEB" : "transparent"}
              />
              <CommonBtn
                type="button"
                property="var(--main-color)"
                color="white"
                disabled={member.point < 100 ? true : false}
              >
                적용하기
              </CommonBtn>
            </AddressGrid>
          </InputWrap>
          <select onChange={useCouponChangePrice}>
            <option value={0}>선택안함</option>
            {couponList.length > 0 ? (
              couponList
                .filter((v) => v.minPrice >= price)
                .map((v, i) => (
                  <option
                    key={v.id}
                    value={JSON.stringify({
                      id: v.id,
                      price: v.discountPrice,
                      rate: v.discountRate,
                      name: v.couponName,
                    })}
                  >
                    {v.couponName +
                      ` ${
                        v.discountPrice > 0
                          ? v.discountPrice
                          : v.discountRate + "%"
                      } 할인`}
                  </option>
                ))
            ) : (
              <option value={0}>사용가능한 쿠폰이 없습니다.</option>
            )}
          </select>
          <RadioInner>

            {/*<RadioWrap>
              <RadioInput
                name="method"
                id="kakao"
                type={"radio"}
                value={method}
                onChange={(e) => e.target.checked && setMethod("kakaopay")}
              />
              <label htmlFor="kakao">카카오페이</label>
            </RadioWrap>

            <RadioWrap>
              <RadioInput
                name="method"
                id="naver"
                type={"radio"}
                value={method}
                onChange={(e) => e.target.checked && setMethod("naverpayCard")}
              />
              <label htmlFor="naver">네이버페이</label>
            </RadioWrap>*/}

            <RadioWrap>
              <RadioInput
                name="method"
                id="card"
                defaultChecked
                type={"radio"}
                value={method}
                onChange={(e) => e.target.checked && setMethod("card")}
              />
              <label htmlFor="card">신용카드</label>
            </RadioWrap>
          </RadioInner>
          <div className="mobile-visiable mobile-price">
            <CartPrice />
          </div>
          <TermsWrap>
            <CheckTerms>
              <CheckBox
                type="checkbox"
                name="all"
                id="all"
                onChange={checkAll}
                checked={
                  checked.age && checked.terms && checked.privacy ? true : false
                }
              />
              <label htmlFor="all">
                <label
                  className="checkbox"
                  onClick={() => {
                    checked.age && checked.terms && checked.privacy
                      ? setChecked({ age: false, terms: false, privacy: false })
                      : setChecked({ age: true, terms: true, privacy: true });
                  }}
                ></label>
                모두 동의합니다.
              </label>
            </CheckTerms>
            <div className="mobile-hidden" style={{ height: "13px" }} />
            <div className="mobile-visiable" style={{ height: "6px" }} />
            <CheckTerms
              onClick={() => setChecked({ ...checked, age: !checked.age })}
            >
              <CheckBox
                type="checkbox"
                name="age"
                id="age"
                checked={checked.age}
              />
              <label htmlFor="age" style={{ zIndex: "-1" }}>
                <label className="checkbox"></label>(필수)본인은 만 14세 미만이
                아닙니다.
              </label>
            </CheckTerms>
            <CheckTerms
              onClick={() => setChecked({ ...checked, terms: !checked.terms })}
            >
              <CheckBox
                type="checkbox"
                name="terms"
                id="terms"
                checked={checked.terms}
              />
              <label htmlFor="terms" style={{ zIndex: "-1" }}>
                <label className="checkbox"></label>(필수)이용약관에 동의합니다.
              </label>
            </CheckTerms>
            <CheckTerms
              onClick={() =>
                setChecked({ ...checked, privacy: !checked.privacy })
              }
            >
              <CheckBox
                type="checkbox"
                name="privacy"
                id="privacy"
                checked={checked.privacy}
              />
              <label htmlFor="privacy" style={{ zIndex: "-1" }}>
                <label className="checkbox"></label>
                (필수)개인정보처리방침에 동의합니다.
              </label>
            </CheckTerms>
          </TermsWrap>
        </MainInner>
        <FlexButton>
          <CommonBtn
            type="button"
            property="white"
            onClick={() => navigate(-1)}
          >
            이전 단계로
          </CommonBtn>
          <CommonBtn
            type="button"
            property="var(--main-color)"
            color="white"
            disabled={
              !checked.age || !checked.terms || !checked.privacy ? true : false
            }
            //onClick={() => navigate('/payment/complete')}
            onClick={serverAuth}
          >
            결제하기
          </CommonBtn>
        </FlexButton>
      </form>
    </PaymentWrap>
  );
};

export default PayMethod;
