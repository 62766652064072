import React, { useState } from 'react';

import axios from 'axios';

import { LoginWrapper } from '../../../components/login/LoginInput';
import Nav, { FindInfoFlex, FindInfoGridInput, FindInfoInner } from '../../../components/find/Nav';
import { CommonBtn } from '../../../components/payment/ShipInfo';

const FindId = () => {
    const [phone, setPhone] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [reMessage, setReMessage] = useState(false);

    const [number, setnumber] = useState('');

    const verifFnc = async () => {
        alert('인증번호가 발송되었습니다.\n 핸드폰을 확인해주세요.');

        let authNum = [];
        for (let i = 1; i <= 6; i++) {
            let random = Math.floor(Math.random() * 9) + 1;

            authNum.push(random);
        }

        setnumber(authNum.join(''));

        console.log(authNum);

        try {
             await axios
                .post(`${process.env.REACT_APP_DOMAIN}/api/send-one.php`, {
                     from: '01056069803',
                     to: phone,
                     number: authNum.join(''),
                })
                .then((res) => {
                    setReMessage(true);
                })
                .catch((e) => alert('다시 시도해주세요.'));
        } 
        catch (error) 
        {
            alert('다시 시도해주세요.');
        }

    };

    const checkAndFindId = () => {
        if (number != authCode) {
            alert('인증번호가 일치하지 않습니다.\n 다시 입력해주세요.');
            return false;
        }
        else
        {
            console.log('인증번호 OK');

            try {
                axios
                   .post(`${process.env.REACT_APP_DOMAIN}/api/send-id.php`, {
                        from: '01056069803',
                        to: phone
                   })
                   .then((res) => {
                       alert("ID정보가 가입 휴대폰으로 발송되었습니다.");
                   })
                   .catch((e) => alert('다시 시도해주세요.'));
           } 
           catch (error) 
           {
               alert('다시 시도해주세요.');
           }

            return true;
        }
    };

    return (
        <LoginWrapper>
            <Nav />
            <FindInfoInner>
                <FindInfoFlex>
                    <label htmlFor="findIdInput">
                        <small>휴대폰 번호</small>
                    </label>
                    {reMessage && (
                        <button type="button" onClick={verifFnc}>
                            <small style={{ textDecoration: 'underline' }}>재전송</small>
                        </button>
                    )}
                </FindInfoFlex>
                <FindInfoGridInput>
                    {!reMessage && (
                        <input
                            type="text"
                            name="findIdInput"
                            id="findIdInput"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="‘-’ 없이 숫자만 입력"
                        />
                    )}
                    {reMessage && (
                        <input
                            type="text"
                            name="authCode"
                            id="authCode"
                            value={authCode}
                            onChange={(e) => setAuthCode(e.target.value)}
                            placeholder="6자리 코드 입력"
                        />
                    )}
                    {!reMessage ? (
                        <CommonBtn property="var(--main-color)" color="white" onClick={verifFnc}>
                            인증요청
                        </CommonBtn>
                    ) : (
                        <CommonBtn property="var(--main-color)" color="white" onClick={checkAndFindId}>
                            인증확인
                        </CommonBtn>
                    )}
                </FindInfoGridInput>
            </FindInfoInner>
        </LoginWrapper>
    );
};

export default FindId;
