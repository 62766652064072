import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { decreaseCount, deleteGoods, increaseCount } from '../../store/cartSlice';

import styled from 'styled-components';

import formPrice from '../../const/formPrice';
import { CountDiv } from '../common/SubDetail';

const CartList = ({ heigth, payment }) => {
    let cartList = useSelector((state) => state.cart);
    let dispatch = useDispatch();

    return (
        <CardList property={heigth}>
            {cartList[0] ? (
                cartList.map((v, i) => (
                    <CartCard key={i}>
                        <img alt="goods" src={process.env.REACT_APP_DOMAIN + v.thumb_src} />
                        <CartCardContetns>
                            <p style={{ fontWeight: '500' }}>{v.goodsName}</p>
                            <p>
                                ₩&nbsp;
                                {formPrice(v.price)}
                            </p>
                            {!payment && (
                                <CountDiv className="absoluteBtn">
                                    <button
                                        disabled={v.count > 1 ? false : true}
                                        onClick={() => {
                                            v.count === 1 ? (v.count = 1) : dispatch(decreaseCount(v.id));
                                        }}
                                    >
                                        -
                                    </button>
                                    <p>{v.count}</p>
                                    <button onClick={() => dispatch(increaseCount(v.id))}>+</button>
                                </CountDiv>
                            )}
                            {!payment && (
                                <button className="absolute" onClick={() => dispatch(deleteGoods(v.id))}>
                                    <img alt="icon" src="/images/icon_trash.svg" width={19} height={19} />
                                </button>
                            )}
                            {payment && <p style={{ position: 'absolute', bottom: '5px' }}>수량 {v.count}개</p>}
                        </CartCardContetns>
                    </CartCard>
                ))
            ) : (
                <div className="clean-cart">
                    <small>장바구니에 담긴 제품이 없습니다.</small>
                </div>
            )}
        </CardList>
    );
};

export default CartList;

const CardList = styled.div`
    position: relative;
    width: 100%;
    height: ${(props) => (props.property ? props.property : '-webkit-calc(100% - 235px)')};

    overflow-y: auto;

    .clean-cart {
        margin-top: 40px;
    }

    .clean-cart small {
        font-weight: 500;
        font-size: var(--font-size-sm);
    }

    @media screen and (max-width: 700px) {
        height: ${(props) => (props.property ? props.property : '-webkit-calc(100% - 220px)')};

        .clean-cart {
            margin-top: 20px;
        }
    }
`;

const CartCard = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 10px;

    padding: 20px 0;

    border-bottom: 1px solid var(--light-color);

    img {
        position: relative;
        width: 104px;
        height: 129px;
        object-fit: cover;
    }

    p {
        font-size: var(--font-size-sm);
        padding-top: 2px;
    }
`;

const CartCardContetns = styled.div`
    position: relative;
    width: 100%;

    .absolute {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    img {
        position: relative;
        width: 19px;
        height: 19px;
    }
`;
