import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import axios from 'axios';

import history from '../../../const/history';

import { initialGoods } from '../../../store/cartSlice';
import { deletePrice } from '../../../store/PaymentPriceSlice';

import Complete from '../../../components/payment/Complete';
import { PaymentWrap } from '../../../components/payment/ShipInfo';

const CompleteOrder = () => {
    const params = useParams();

    let cartList = useSelector((state) => state.cart);
    let dispatch = useDispatch();

    const [payment, setPayment] = useState('');
    const [orders, setOrders] = useState('');
    const [locationKeys, setLocationKeys] = useState([]);

    params.completeId &&
        (!payment || !orders) &&
        axios
            .get(`${process.env.REACT_APP_DOMAIN}/api/order_end.php?o_idx=` + params.completeId)
            .then((res) => {

                console.log("res : " + res.data.result);
                
                if (res.data.result === '0000') {
                    setOrders(res.data.orders);
                    setPayment(res.data.payment);
                }
            })
            .catch((e) => console.log(e));

    useEffect(() => {
        cartList && dispatch(initialGoods());
        dispatch(deletePrice());
    }, []);

    useEffect(() => {
        if (history.action === 'POP') {
            if (locationKeys[1] === window.location.key) {
                setLocationKeys(([_, ...keys]) => keys);

                // 앞으로 가기
            } else {
                setLocationKeys((keys) => [window.location.key, ...keys]);

                // 뒤로 가기
                history.push('/detail');
            }
        }
    }, []);

    return (
        <PaymentWrap>
            <p className="title mobile-hidden">주문이 완료 되었습니다</p>
            <Complete orders={orders} payment={payment} />
        </PaymentWrap>
    );
};

export default CompleteOrder;
