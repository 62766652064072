import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IconWrap } from '../../../../../components/login/LoginInput';
import { MainContents } from '../../../../../components/user/UserComponent';
import { CommonBtn } from '../../../../../components/payment/ShipInfo';
import axios from 'axios';

const NewPassword = () => {
    const memberData = useSelector((state) => state.member);
    const navigate = useNavigate();

    const [pass, setPass] = useState('');
    const [checkPass, setcheckPass] = useState();
    const [display, setDisplay] = useState({
        pass: false,
        check: false,
    });

    const changePasswordHandler = async () => {
        if (pass != checkPass) return false;

        await axios
            .post(`${process.env.REACT_APP_DOMAIN}/api/member/change-pass.php`, {
                id: memberData.id.toString(),
                password: pass,
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    navigate('/mypage/info');
                } else {
                    alert('다시 시도해주세요.');
                }
            })
            .catch((err) => alert('비밀번호가 일치하지 않습니다.\n' + err));
    };

    return (
        <div>
            <p className="title">비밀번호 재설정</p>
            <MainContents>
                <div className="input-wrap">
                    <label htmlFor="password3">새로운 비밀번호</label>
                    <div className="blank" />
                    <div className="input-div">
                        <input
                            type={display.pass ? 'text' : 'password'}
                            id="password3"
                            value={pass}
                            placeholder="비밀번호"
                            onChange={(e) => setPass(e.target.value)}
                        />
                        <IconWrap onClick={() => setDisplay({ pass: !display.pass })}>
                            <img
                                alt="logo"
                                src={display.pass ? '/images/icon_display.svg' : '/images/icon_hidden.svg'}
                            />
                        </IconWrap>
                    </div>
                </div>
                <div className="input-wrap">
                    <label htmlFor="password3">새로운 비밀번호 확인</label>
                    <div className="blank" />
                    <div className="input-div">
                        <input
                            type={display.check ? 'text' : 'password'}
                            id="password3"
                            value={checkPass}
                            placeholder="비밀번호"
                            onChange={(e) => setcheckPass(e.target.value)}
                        />
                        <IconWrap onClick={() => setDisplay({ check: !display.check })}>
                            <img
                                alt="logo"
                                src={display.check ? '/images/icon_display.svg' : '/images/icon_hidden.svg'}
                            />
                        </IconWrap>
                    </div>
                </div>
                <div className="blank" />
                <div className="blank" />
                <CommonBtn onClick={changePasswordHandler} property="var(--main-color)" color="white">
                    비밀번호 변경하기
                </CommonBtn>
            </MainContents>
        </div>
    );
};

export default NewPassword;
