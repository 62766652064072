import React from 'react';

import { useDispatch } from 'react-redux';
import { removeMember } from '../../store/memberSlice';

import styled from 'styled-components';
import axios from 'axios';

const UserNav = ({ path }) => {
    let dispatch = useDispatch();

    const onLogout = async () => {
        await axios
            .get(`${process.env.REACT_APP_DOMAIN}/api/logout.php`)
            .then(() => dispatch(removeMember()))
            .then(() => (window.location.href = '/'));
    };

    return (
        <NavWrap>
            <NavList>
                <li className={path === '/mypage' ? 'mypage-here' : ''}>
                    <a href="/mypage">
                        <p>마이페이지</p>
                    </a>
                </li>
                <li className={path.includes('/mypage/order') ? 'mypage-here' : ''}>
                    <a href="/mypage/order">
                        <p>주문/배송</p>
                    </a>
                </li>
                <li className={path.includes('/mypage/coupon') ? 'mypage-here' : ''}>
                    <a href="/mypage/coupon">
                        <p>이벤트쿠폰</p>
                    </a>
                </li>
                <li
                    className={path.includes('/mypage/info') || path === '/mypage/change-password' ? 'mypage-here' : ''}
                >
                    <a href="/mypage/info">
                        <p>회원정보</p>
                    </a>
                </li>
                <li className={path.includes('/mypage/address') ? 'mypage-here' : ''}>
                    <a href="/mypage/address">
                        <p>주소록</p>
                    </a>
                </li>
                <li className={path === '/mypage/inquiries' ? 'mypage-here' : ''}>
                    <a href="/mypage/inquiries">
                        <p>1:1문의하기</p>
                    </a>
                </li>
                <li onClick={onLogout} style={{ cursor: 'pointer' }}>
                    <p>로그아웃</p>
                </li>
            </NavList>
        </NavWrap>
    );
};

export default UserNav;

const NavWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100px;

    margin-left: auto;

    @media screen and (max-width: 700px) {
        margin: 0;
    }
`;

const NavList = styled.ul`
    position: relative;
    width: 100%;
    height: fit-content;

    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 40px;

    color: var(--light-color);

    .mypage-here {
        color: var(--main-color);
    }

    p {
        font-size: var(--font-size-sm);
    }

    @media screen and (max-width: 700px) {
        display: none;
    }
`;
