import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { insertGoods } from "../../store/cartSlice";

import { StyleButton } from "./SubDetail";

const AddcartBtn = ({ data, count }) => {
  const dispatch = useDispatch();
  let cartList = useSelector((state) => state.cart);
  const addCartList = async () => 
  {

    //dispatch(initialGoods());

    dispatch(
      insertGoods({
        id: data.id,
        goodsName: data.goodsName,
        thumb_src: data.thumb_src,
        price: data.price,
        count: count,
      })
    );

    alert("장바구니에 추가 되었습니다.");
  };

  return (
    <StyleButton bg="white" onClick={addCartList}>
      장바구니 추가
    </StyleButton>
  );
};

export default AddcartBtn;
