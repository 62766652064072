import React, { useEffect, useState } from 'react';
import CheckCondition from '../../components/login/CheckCondition';
import LoginButton from '../../components/login/LoginButton';

import { BlackBtn } from '../../components/common/BlackBtn';

import { IconWrap, LoginWrapper, WarningText } from '../../components/login/LoginInput';
import SignInput, {
    BirthSelected,
    CheckPassword,
    CheckPasswordWrap,
    InputWrap,
    SignSelect,
    SignWrap,
} from '../../components/login/SignInput';
import axios from 'axios';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [checkEmail, setCheckEmail] = useState(true);
    const [display, setDisplay] = useState({
        pass: false,
        check: false,
    });
    const [pass, setPass] = useState('');
    const [checkPass, setCheckPass] = useState('');

    const [sex, setSex] = useState(0);
    const [birth, setBirth] = useState({
        year: sessionStorage.getItem('birth').substring(0, 4),
        month: sessionStorage.getItem('birth').substring(5, 7),
        day: sessionStorage.getItem('birth').substring(8),
    });

    const [checkOn, setCheckOn] = useState({
        first: false,
        second: false,
        third: false,
    });

    const [terms, setTerms] = useState({
        age: false,
        terms: false,
        privacy: false,
        marketing: false,
        news: false,
    });

    useEffect(() => {
        setCheckOn({
            first: /\d/.test(pass) ? true : false,
            //eslint-disable-next-line
            second: /[\[\]{}()<>?|~!@#$%^&*()_+=,.:\"\\\'\\]/g.test(pass) ? true : false,
            third: pass.length > 7 ? true : false,
        });
    }, [pass]);

    const onSaveMember = async (e) => {
        if (!email || !pass || !checkPass)
        {
            alert("회원 이메일, 비번정보를 정확하게 입력해 주세요. "); 
            return false;
        }
        if (!terms.age || !terms.terms || !terms.privacy) 
        {
            alert("필수 약관에 동의해주셔야 합니다. "); 
            return false;
        }

        try {
            await axios
                .post(`${process.env.REACT_APP_DOMAIN}/api/member.php`, {
                    email: email,
                    password: pass,
                    name: sessionStorage.getItem('name'),
                    birth: birth.year + '-' + birth.month + '-' + birth.day,
                    phone: sessionStorage.getItem('phone'),
                    marketing: terms.marketing ? 'Y' : 'N',
                    news: terms.marketing ? 'Y' : 'N',
                    point: '0',
                })
                .then((res) => {
                    console.log( " Join OK ===> " );
                    console.log( res );
                    window.location.href = '/complete';
                })
                .catch((err) => console.log(err));
        } catch (error) {}
    };

    console.log(sessionStorage.getItem('name'));
    return (
        <LoginWrapper>
            <p>간편 회원가입</p>
            <SignWrap>
                <p>아이디(이메일)</p>
                <InputWrap>
                    <input
                        type={'text'}
                        id="email"
                        value={email}
                        placeholder="이메일 입력"
                        onChange={(e) => {
                            e.target.value.includes('@') && e.target.value.includes('.')
                                ? setCheckEmail(true)
                                : setCheckEmail(false);

                            setEmail(e.target.value);
                        }}
                    />
                    {!checkEmail && (
                        <WarningText>
                            <img alt="icon" src="/images/icon_warning.svg" />
                            이메일 형식에 맞게 입력해 주세요
                        </WarningText>
                    )}
                </InputWrap>
                <p>비밀번호</p>
                <InputWrap>
                    <input
                        type={display.pass ? 'text' : 'password'}
                        id="password"
                        value={pass}
                        placeholder="8자리 영어, 숫자, 특수문자 입력 가능"
                        onChange={(e) => setPass(e.target.value)}
                    />
                    {
                        <IconWrap onClick={() => setDisplay({ pass: !display.pass })}>
                            <img
                                alt="logo"
                                src={display.pass ? '/images/icon_display.svg' : '/images/icon_hidden.svg'}
                            />
                        </IconWrap>
                    }
                </InputWrap>
                <p>비밀번호 확인</p>
                <InputWrap>
                    <input
                        type={display.check ? 'text' : 'password'}
                        id="checkPass"
                        value={checkPass}
                        placeholder="비밀번호를 한번 더 입력"
                        onChange={(e) => setCheckPass(e.target.value)}
                    />
                    {
                        <IconWrap onClick={() => setDisplay({ check: !display.check })}>
                            <img
                                alt="logo"
                                src={display.check ? '/images/icon_display.svg' : '/images/icon_hidden.svg'}
                            />
                        </IconWrap>
                    }
                </InputWrap>
                <p>다음을 활용한 보안 비밀번호를 사용하시기 바랍니다.</p>
                <CheckPasswordWrap>
                    <CheckPassword property={checkOn.first.toString()}>
                        <img alt="icon" src={checkOn.first ? '/images/icon_checkOn.svg' : '/images/icon_check.svg'} />
                        <p>최소 1개의 숫자 사용</p>
                    </CheckPassword>
                    <CheckPassword property={checkOn.second.toString()}>
                        <img alt="icon" src={checkOn.second ? '/images/icon_checkOn.svg' : '/images/icon_check.svg'} />
                        <p>최소 1개의 특수문자 사용</p>
                    </CheckPassword>
                    <CheckPassword property={checkOn.third.toString()}>
                        <img alt="icon" src={checkOn.third ? '/images/icon_checkOn.svg' : '/images/icon_check.svg'} />
                        <p>최소 8자 이상</p>
                    </CheckPassword>
                </CheckPasswordWrap>
                <p>성별</p>
                <SignSelect value={sex} set={setSex} />
                <p>생년월일</p>
                <BirthSelected type="birth" value={birth} set={setBirth} />
                <CheckCondition check={terms} set={setTerms} />
            </SignWrap>

            <BlackBtn type="button" onClick={onSaveMember}>
                가입하기
            </BlackBtn>
        </LoginWrapper>
    );
};

export default SignUp;
