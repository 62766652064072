import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  ShipmentEmpty,
  ShipmentList,
} from "../../../../components/user/Shipment";
import { BlackBtn } from "../../../../components/common/BlackBtn";

const MypageAddress = () => {
  const navigate = useNavigate();
  const memberData = useSelector((state) => state.member);

  const [data, setData] = useState(Array);

  useEffect(() => {
    data.length === 0 &&
      axios
        .get(`${process.env.REACT_APP_DOMAIN}/api/shipment/adr.php?id=${memberData.id}&page=0&size=5`)
        .then((res) => {
          setData(res.data.list.content);
        });
  }, []);
  console.log(data);

  const deleteShipmentHandler = async (id) => {
    if (!window.confirm("정말 삭제하시겠습니까?")) return false;

    await axios
      .delete(`${process.env.REACT_APP_DOMAIN}/api/shipment/adr_del.php?memId=${memberData.id}&addrId=${id}`)
      .then((res) => {
        if (res.data.result === "0000") {
          window.location.reload();
        } else {
          alert("다시 시도해주세요.");
        }
      })
      .catch((e) => alert("다시 시도해주세요,\n" + e));
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="title">주소록</p>
        <img
          className="mobile-visible"
          alt="←"
          src="/images/leftarrow.svg"
          width={15}
          height={15}
          onClick={() => navigate("/mypage")}
        />
      </div>

      {data.length > 0 ? (
        <ShipmentList>
          {data.map((v, i) => (
            <div key={i} className="ship-btn">
              <div className="flex">
                <p>{v.sname}</p>
                {v.baseAddress === "Y" && (
                  <div className="baseAdr">기본배송지</div>
                )}
              </div>
              <small>{v.address}</small>
              <br />
              <small>{v.adrDetail}</small>
              <div className="br" />
              <small>{v.phone}</small>
              <div className="br" />
              <div className="br" />
              <div className="flex">
                <button
                  type="button"
                  onClick={() => deleteShipmentHandler(v.id)}
                >
                  삭제
                </button>
                <button
                  type="button"
                  onClick={() => navigate(`/mypage/address/${v.id}`)}
                >
                  수정
                </button>
              </div>
            </div>
          ))}
        </ShipmentList>
      ) : (
        <ShipmentEmpty>
          <p>등록된 주소가 없습니다.</p>
        </ShipmentEmpty>
      )}
      <div style={{ maxWidth: "500px" }}>
        <BlackBtn
          type="button"
          onClick={() => navigate("/mypage/address/post")}
        >
          <p>배송지 추가</p>
        </BlackBtn>
      </div>
    </div>
  );
};

export default MypageAddress;
