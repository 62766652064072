import React, { useState } from "react";
import styled from "styled-components";
import Popup from "../popup/Popup";

const HeaderLine = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <HeaderLineWrapper id="header-line" onClick={() => setOpen(true)}>
        <div className="swiper-wrap">
          <p>무료 배송 & 선물 포장 서비스 혜택</p>
        </div>
      </HeaderLineWrapper>
      <Popup open={open} set={setOpen} />
    </>
  );
};

export default HeaderLine;

const HeaderLineWrapper = styled.div`
  width: 100%;
  top: 0;
  z-index: 3;
  position: relative;
  display: grid;
  align-items: center;

  background-color: var(--main-color);
  color: white;

  transform: translateY(0);

  .swiper-wrap {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 30px; /* 고정된 높이 설정 */
    line-height: 35px;
    margin: 0 auto;

    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  p {
    margin: 0 auto;
    text-align: center;
    font-size: var(--font-size-sm);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 30px;
    cursor: pointer;
  }
`;
