import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

const Footer = () => {
    const memberData = useSelector((state) => state.member);

    return (
        <FooterWrapper>
            <FooterLogo>
                <img alt="icon" src="/images/logo_white.svg" width={150} height={14} />
                <FooterLine />
            </FooterLogo>
            <FooterList>
                <div
                    style={{
                        width: 'fit-content',
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    }}
                >
                    <ListDiv>
                        <p>브랜드</p>
                        <ul>
                            <Link to={'/about'}>
                                <li>브랜드&nbsp;스토리</li>
                            </Link>
                            <button
                                onClick={() =>
                                    window.open(
                                        'https://www.instagram.com/competo/',
                                        '_blank',
                                        'width=700, height=600, top=50, left=50, scrollbars=yes'
                                    )
                                }
                            >
                                <li>인스타그램</li>
                            </button>
                        </ul>
                    </ListDiv>
                    <ListDiv>
                        <p>법적고지</p>
                        <ul>
                            <Link to={'/terms-of-use'}>
                                <li>이용약관</li>
                            </Link>
                            <Link to={'/privacy-policy'}>
                                <li>개인정보처리</li>
                            </Link>
                            <Link to={'/cookie-policy'}>
                                <li>쿠키정책</li>
                            </Link>
                        </ul>
                    </ListDiv>
                    <ListDiv>
                        <p>고객정보</p>

                        {memberData.id > 0 ? (
                            <ul>
                                <Link to={'/mypage/order'}>
                                    <li>주문/배송</li>
                                </Link>
                            </ul>
                        ) : (
                            <ul>
                                <Link to={'/login'}>
                                    <li>로그인</li>
                                </Link>
                                <Link to={'/verification'}>
                                    <li>회원가입</li>
                                </Link>
                            </ul>
                        )}
                    </ListDiv>
                    <ListDiv>
                        <p>고객서비스</p>
                        <ul>
                            <Link to={'/mypage/inquiries'}>
                                <li>1:1 문의</li>
                            </Link>
                            <Link to={'mailto:sevice@competoseoul.com'}>
                                <li>기업문의</li>
                            </Link>
                            <li></li>
                        </ul>
                    </ListDiv>
                </div>
                <FooterLine />
            </FooterList>
            <FooterInfo>
                <p>BLACK BOY&nbsp;&nbsp;&nbsp;</p>
                <small style={{ fontWeight: '500' }}>
                    <span className="mobile-hidden">ㅣ</span> CEO:&nbsp;KIM DAE WON
                    <span>ㅣ</span>&nbsp;PHONE:&nbsp;010-5606-9803
                    <span>ㅣ</span>&nbsp;ONE-LINE REGISTER: 2024-서울강북-0686호
                    <span>ㅣ</span>&nbsp;CONTACT:&nbsp;service@competoseoul.com
                    <span>ㅣ</span>BUSINESS LISENCE:&nbsp;212-50-01058
                    <Link
                        to={'/'}
                        onClick={() => {
                            let url = 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2125001058';
                            window.open(url, 'bizCommPop', 'width=750, height=700;');
                        }}
                    >
                        CHECK
                    </Link>
                    <span> ㅣ</span>41, WOLGYE-RO 21GA-GIL, GANGBUK-GU, SEOUL

                </small>
                <FooterLine />
            </FooterInfo>
            <FooterInfo>
                <small>©2024 Our Love for Black, COMPETO.</small>
            </FooterInfo>
        </FooterWrapper>
    );
};

export default Footer;

const FooterWrapper = styled.footer`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    color: var(--light-color);
`;

const FooterLogo = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    padding: 40px 35px var(--wrapper-padding);

    @media screen and (max-width: 700px) {
        padding: 20px var(--wrapper-padding);

        img {
            position: relative;
            width: auto;
            height: 10px;
        }
    }
`;

const FooterLine = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;

    background-color: #CDCDCD;
`;

const FooterList = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    font-size: var(--font-size-sm);

    padding: 20px var(--wrapper-padding);

    div {
        gap: 30px;
    }

    @media screen and (max-width: 700px) {
        div {
            width: 100% !important;
            gap: 15px;
        }
    }
`;

const ListDiv = styled.div`
    position: relative;
    width: fit-content;
    height: 100%;

    p {
        margin-bottom: 20px;
        color: white;
    }

    ul {
        display: grid;
        gap: 15px;
    }

    @media screen and (max-width: 700px) {
        p {
            font-weight: 600;
        }

        ul li {
            font-weight: 500;
        }
    }
`;

const FooterInfo = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: flex;

    font-size: var(--font-size-xs);

    padding: 10px var(--wrapper-padding);

    small {
        font-size: var(--font-size-xs);
        display: flex;
        gap: 10px;
    }

    a {
        text-decoration: underline;
    }

    p {
        color: white;
    }

    @media screen and (max-width: 700px) {
        display: block;

        p {
            margin-bottom: 10px;
        }

        small {
            flex-flow: wrap;
            gap: 2.5px;
            font-weight: 500;
        }

        a {
            text-decoration: none;
        }
    }
`;
