import React, { useState } from "react";

import axios from 'axios';

import {
  LoginWrapper,
  WarningText,
} from "../../../components/login/LoginInput";
import Nav, {
  FindInfoGridInput,
  FindInfoInner,
} from "../../../components/find/Nav";
import { CommonBtn } from "../../../components/payment/ShipInfo";

const FindPassword = () => {
  const [email, setEmail] = useState("");
  const [checkEmail, setCheckEmail] = useState(true);

  const checkAndResetPw = () => {
    
      try {
            axios
               .post(`${process.env.REACT_APP_DOMAIN}/api/send-pw.php`, {
                    from: '01056069803',
                    to: email
               })
               .then((res) => {
                   alert("PW정보가 가입 휴대폰으로 발송되었습니다.");
               })
               .catch((e) => alert('다시 시도해주세요.'));
       } 
       catch (error) 
       {
           alert('다시 시도해주세요.');
       }

      return true;
};

  return (
    <LoginWrapper>
      <Nav />
      <FindInfoInner>
        <span className="mobile-visible">
          <p>
            가입한 아이디(이메일)를 입력해주세요.
            <br />
            발송된 링크를 통해 비밀번호 변경이 가능합니다.
          </p>
        </span>
        <label htmlFor="findPwInput">
          <small>아이디(이메일)</small>
        </label>
        <FindInfoGridInput>
          <input
            type="text"
            name="findPwInput"
            id="findPwInput"
            value={email}
            onChange={(e) => {
              e.target.value.includes("@") && e.target.value.includes(".")
                ? setCheckEmail(true)
                : setCheckEmail(false);

              setEmail(e.target.value);
            }}
            placeholder="이메일 입력"
          />
          <CommonBtn property="var(--main-color)" color="white" onClick={checkAndResetPw}  >
            이메일&nbsp;발송
          </CommonBtn>
        </FindInfoGridInput>
        {!checkEmail && (
          <WarningText>
            <img alt="icon" src="/images/icon_warning.svg" />
            이메일 형식에 맞게 입력해 주세요
          </WarningText>
        )}
      </FindInfoInner>
    </LoginWrapper>
  );
};

export default FindPassword;
