import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MainContents } from '../../../../components/user/UserComponent';

import { IconWrap } from '../../../../components/login/LoginInput';
import { CommonBtn } from '../../../../components/payment/ShipInfo';
import axios from 'axios';
const MypageChangePass = () => {
    const [display, setDisplay] = useState(false);
    const [pass, setPass] = useState('');

    const navigate = useNavigate();
    const memberData = useSelector((state) => state.member);

    const checkPass = async () => {
        if (!pass) return false;

        await axios
            .post(`${process.env.REACT_APP_DOMAIN}/api/member/check-pass.php`, {
                id: memberData.id.toString(),
                password: pass,
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    navigate('/mypage/new-password');
                } else {
                    alert('다시 시도해주세요.');
                }
            })
            .catch((err) => alert('비밀번호가 일치하지 않습니다.\n' + err));
    };

    return (
        <div>
            <p className="title">비밀번호 재설정</p>
            <MainContents>
                <div className="input-div">
                    <input
                        type={display ? 'text' : 'password'}
                        id="password"
                        value={pass}
                        placeholder="이전 비밀번호 입력"
                        onChange={(e) => setPass(e.target.value)}
                    />
                    <IconWrap onClick={() => setDisplay(!display)}>
                        <img alt="logo" src={display ? '/images/icon_display.svg' : '/images/icon_hidden.svg'} />
                    </IconWrap>
                </div>
                <div style={{ height: '40px' }}></div>
                <CommonBtn onClick={checkPass} property="var(--main-color)" color="white">
                    확인
                </CommonBtn>
            </MainContents>
        </div>
    );
};

export default MypageChangePass;
