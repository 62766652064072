import React, { useEffect, useState } from 'react';
import axios from 'axios';

import SubTitle from '../../components/common/SubTitle';
import SubCard from '../../components/common/SubCard';
import { FlexDiv, WrapperDiv } from '../../components/home/BestSeller';
import { GridDiv } from '../Wallets';
import CustomSelect from '../../components/common/CustomSelect';
import sortList from '../../const/SortList';

const BestSeller = () => {
    const [data, setData] = useState(Array);
    const [sort, setSort] = useState(sortList[0]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_DOMAIN}/api/goods/best.php?page=0&sort=${sort.id}&size=10`)
            .then((res) => {
                setData(res.data.dto.content);
                console.log(res.data.dto);
            })
            .catch((e) => console.log(e));
    }, [sort]);
    return (
        <div style={{ position: 'relative', width: '100%', height: 'fit-content' }}>
            <SubTitle title="베스트 셀러" />
            <WrapperDiv>
                {/*<FlexDiv>
                    <p>
                        베스트&nbsp;셀러&nbsp;|&nbsp;
                        <span>많은 고객이 주목한 콤페토의 베스트 제품</span>
                    </p>
                    <CustomSelect sort={sort} setSort={setSort} />
                </FlexDiv>*/}
                <GridDiv>
                {data[0] &&
                        data[0].goods &&
                        data.map((v, i) => (
                            <SubCard
                                key={i}
                                link={'/bags/' + v.goods.id}
                                img={v.goods.thumb_src}
                                price={v.goods.price}
                                title={v.goods.goodsName}
                            />
                        ))}
                </GridDiv>
            </WrapperDiv>
        </div>
    );
};
export default BestSeller;
