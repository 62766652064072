import React from "react";
import { AbsoluteTxt, GridDiv } from "./Banner";
import { Link, useNavigate } from "react-router-dom";
import { ImgDiv } from "./ProductByCategory";

const SecondBanner = ({ data }) => {
  const navigate = useNavigate();

  return (
    <GridDiv className="end" style={{ height: "auto" }}>
      <ImgDiv className="second-banner second-banner2">
        <img alt="banner" src="/images/banner6.png" />
        {/* <img alt="banner" src="http://competoseoul.cdn1.cafe24.com/banner6.png" /> */}
        <div className="center-div">
          <div>
            <h5>Our Love for Black</h5>
            <button type="button" onClick={() => navigate("/all-items")}>
              <p>Shop Now</p>
            </button>
          </div>
        </div>
      </ImgDiv>
      {data && (
        <ImgDiv
          className="second-banner"
          onClick={() => (window.location.href = `${data.url}`)}
        >
          <img alt="banner" src={data.img} />
          <div className="absolute-div">
            <p>{data.txt1}</p>
            <small>{data.txt2}</small>
          </div>
        </ImgDiv>
      )}
    </GridDiv>
  );
};

export default SecondBanner;
