import React from "react";

import styled from "styled-components";

import { AboutSeo } from "../../const/metatag";

const About = () => {
  return (
    <div>
      <AboutSeo />
      <Wrapper>
        <Video autoPlay muted loop playsInline>
          <source src="/videos/about.mp4" type="video/mp4" />
        </Video>
      </Wrapper>
      <InnerWrapper>
        <AboutTextDiv>
          <h5>우리의 미션</h5>
          <p>
            콤페토는 세상이 만든 프레임에 마주하지 않고 남들과 다른 개성을 찾는
            본능을 깨우기 위해 만든 패션 브랜드입니다.
            <br className="mobile-visiable" />
            우리는 유행에 민감하지 않으며, 우리만의 독창적인 스타일을
            제안합니다. '콤페토'스러움을 새롭게 정의하고 이를 추구합니다.
          </p>
          <p>
            We don’t move with trends, but rather, offer our own unique and
            innovative styles.
            <br className="mobile-hidden" />
            We are a fashion brand that seeks to redefine “COMPETO.”
          </p>
        </AboutTextDiv>
        <img src="/images/img_about01.png" alt="" />
        <AboutTextDiv>
          <h5>우리가 사랑한 블랙</h5>
          <p>
            콤페토의 모든 상품 색상은 검정색으로만 구성되어 있습니다. 우리는
            검정 색상 패션을 지향하며,
            <br className="mobile-visiable" />
            검정 색상을 이용한 다양한 상품들을 끈임없이 연구하고 전개합니다.
            콤페토는 검정 색상을 사랑하는 가죽 디자이너와 브랜드 디자이너 두
            명의 디자이너가 설립한 브랜드입니다.
          </p>
          <p>
            All of our products are composed of the color black. We embrace
            black fashion, continuously
            <br className="mobile-visiable" />
            researching and expanding our product range using the color black.
            <br className="mobile-visiable" />
            COMPETO is a brand founded by two designers, a leather designer and
            a brand designer,
            <br className="mobile-visiable" />
            who share a love for the color black.
          </p>
        </AboutTextDiv>
        <img src="/images/img_about02.png" alt="" />
        <AboutTextDiv>
          <h5>컴팩트한 수납 공간</h5>
          <p>
            콤페토는 수납 공간의 효율성과 실용성에 집중하고 있습니다.
            <br className="mobile-visiable" />
            우리는 작고 경제적인 디자인으로 더 많은 물건을 보관할 수 있도록
            노력합니다.
          </p>
          <p>
            We strive to create compact spaces that allow you to store more
            items with our small,
            <br className="mobile-hidden" />
            yet economical designs.
          </p>
        </AboutTextDiv>
        <img src="/images/img_about03.png" alt="" />
        <AboutTextDiv>
          <h5>한 사람을 위한 노력</h5>
          <p>
            가죽을 재단하는 작업부터 바느질까지 전 과정은 모두 수작업으로
            진행됩니다.
          </p>
          <p>
            From cutting the leather to sewing it together, each piece is
            hand-crafted with the utmost detail and care.
          </p>
        </AboutTextDiv>
      </InnerWrapper>
    </div>
  );
};

export default About;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 885px;

  @media screen and (max-width: 700px) {
    height: calc((var(--dvh, 1dvh) * 100) - 80px);
    // height: 90vh;
  }

  overflow: hidden;
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 886px;
  height: 100%;
  padding: 80px 0;
  margin: 0 auto;

  span {
    position: relative;
    display: block;
    width: 100%;
    height: 886px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  img {
    margin: 80px 0;
    width: 100%;
  }

  .grid {
    position: relative;
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    img {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 700px) {
    span {
      margin: 40px 0;
      height: 700px;
    }

    .grid {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
`;

const AboutTextDiv = styled.div`
  h5 {
    padding-bottom: 35px;
    font-family: Pretendard Variable;
    line-height: 43px; /* 127.778% */
    font-weight: 600;
  }

  p {
    color: #151414;
    font-family: Pretendard Variable;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px; /* 127.778% */
  }
  p + p {
    margin-top: 20px;
  }

  @media screen and (max-width: 700px) {
    padding: 0 var(--wrapper-padding);

    h5 {
      padding-bottom: 20px;
      font-weight: 600;
      font-size: 16pt;
    }

    p {
      font-weight: 500;
      font-size: 13px;
    }
  }
`;

const Video = styled.video`
  position: relative;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-align: center;
  z-index: 0;
`;
