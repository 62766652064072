import React from "react";

import { Link } from "react-router-dom";

import formPrice from "../../const/formPrice";
import { GridDiv, GridInnerDiv, WrapperDiv } from "./BestSeller";

const AllItems = ({ data }) => {
  return (
    <WrapperDiv>
      <h5>모든 아이템</h5>
      <GridDiv>
        {data.length > 0 &&
          data.slice(0, 3).map((v, i) => (
            <GridInnerDiv
              key={i}
              display={i === 2 ? "none" : "grid"}
              onClick={() =>
                (window.location.href = `/best-seller/${v.goods.id}`)
              }
            >
              <div className="img-wrap" style={{ marginBottom: "5px" }}>
                <img
                  alt="icon"
                  src={`${process.env.REACT_APP_DOMAIN}${v.goods.thumb_src}`}
                />
              </div>
              <p className="good-name" style={{ fontWeight: "500" }}>
                {v.goods.goodsName}
              </p>
              <p>₩ {formPrice(v.goods.price)}</p>
            </GridInnerDiv>
          ))}
      </GridDiv>
      <Link to={"/all-items"}>
        <button>
          <p>
            VIEW ALL
            {/* <img
              alt="arrow"
              src="/images/all_arrow.svg"
              style={{ margin: "0 0 0 4px" }}
            /> */}
          </p>
        </button>
      </Link>
    </WrapperDiv>
  );
};

export default AllItems;
