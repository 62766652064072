import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../components/common/Pagination';
import UserComponent, {
  BetweenFlex,
  BlackBtn,
  InputWrap,
  MainContents,
  MonthBtn,
  MonthBtnWrap,
  MonthInput,
  OrderDataWrap
} from '../../../../components/user/UserComponent';
import { dateFormat } from '../../../../const/dateFormat';

const MypageOrder = () => {
    const navigate = useNavigate();

    const [monthBtn, setMonthBtn] = useState(0);
    let member = useSelector((state) => state.member);

    const [data, setData] = useState(Array);
    const [pageble, setPageble] = useState(Array);
    const [page, setPage] = useState(0);
    const [counting, setCounting] = useState('');

    useEffect(() => {
        data.length < 1 &&
            axios
                .get(`/api/order/${member.id}?page=${page}&size=2`)
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto.content);
                        setPageble(res.data.dto);
                    }
                })
                .catch((e) => console.log(e));
    }, [data]);

    useEffect(() => {
        !counting &&
            axios
                .get(`/api/order/${member.id}/counting`)
                .then((res) => {
                    if (res.data.result === '0000') {
                        setCounting(res.data.dto);
                    }
                })
                .catch((e) => console.log(e));
    }, [counting]);

    useEffect(() => {
        axios
            .get(`/api/order/${member.id}?page=${page}&size=2`)
            .then((res) => {
                if (res.data.result === '0000') {
                    setData(res.data.dto.content);
                    setPageble(res.data.dto);
                }
            })
            .catch((e) => console.log(e));
    }, [page]);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p className="title">주문내역/배송현황 </p>
                <img
                    className="mobile-visible"
                    alt="←"
                    src="/images/leftarrow.svg"
                    width={15}
                    height={15}
                    onClick={() => navigate('/mypage')}
                />
            </div>
            <MainContents>
                <UserComponent data={counting} />
                <div style={{ height: '6px' }} />
                <BetweenFlex>
                    <MonthBtnWrap>
                        <MonthBtn onClick={() => setMonthBtn(0)} className={monthBtn === 0 ? 'checkBtn' : ''}>
                            <p>한달</p>
                        </MonthBtn>
                        <MonthBtn onClick={() => setMonthBtn(1)} className={monthBtn === 1 ? 'checkBtn' : ''}>
                            <p>3개월</p>
                        </MonthBtn>
                        <MonthBtn onClick={() => setMonthBtn(2)} className={monthBtn === 2 ? 'checkBtn' : ''}>
                            <p>1년</p>
                        </MonthBtn>
                    </MonthBtnWrap>
                    <InputWrap>
                        <MonthInput type={'date'} />
                        <MonthInput type={'date'} />
                        <BlackBtn>
                            <p>조회</p>
                        </BlackBtn>
                    </InputWrap>
                </BetweenFlex>
                <OrderDataWrap>
                    {data.length > 0 &&
                        data.map((v, i) => (
                            <div key={i} className="order-wrap">
                                <div className="flex-top">
                                    <p>{dateFormat(v.payments[0].paidAt)}</p>
                                    <p onClick={() => navigate('/mypage/order/' + v.id)}>
                                      주문상세
                                      <img alt="arrow" src="/images/all_arrow.svg" style={{margin: '0 0 0 4px'}}/>
                                    </p>
                                </div>
                                <div className="flex-middle">
                                    <div className="img-div">
                                        <img alt="thumb" src={'https://www.competoseoul.com' + v.goods[0].thumb_src} />
                                    </div>
                                    <div className="text-div">
                                        <p className="light">{v.goods[0].goodsName}</p>
                                        <p>₩ {v.goods[0].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>

                                        <p style={{ position: 'absolute', bottom: '0' }}>
                                            {v.oStatus === '0'
                                                ? '상품준비중'
                                                : v.oStatus === '1'
                                                ? '배송중'
                                                : '배송완료'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </OrderDataWrap>
                <Pagination totalPage={pageble.totalPages} curr={page} set={setPage} />
            </MainContents>
        </div>
    );
};

export default MypageOrder;
