import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import SubDetail from "../../../components/common/SubDetail";

const AccessoriesDetail = () => {
  const params = useParams();

  const [data, setData] = useState("");

  params.goodsId &&
    !data &&
    axios.get(`${process.env.REACT_APP_DOMAIN}/api/goods/index.php?goodsId=` + params.goodsId).then((res) => {
      setData(res.data.data);
    });

  return <SubDetail data={data}></SubDetail>;
};

export default AccessoriesDetail;
