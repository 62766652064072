const formPrice = (target) => {
  if (target) 
  {
    var tmp = target.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // return target.toLocaleString("ko-KR");
    return tmp;
  }
};

export default formPrice;
