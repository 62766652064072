import React from "react";
import { useLocation } from "react-router-dom";

import styled from "styled-components";

const SubTitle = ({ title }) => {
  const { pathname } = useLocation();
  let src = "";
  let mobilesrc = "";

  switch (pathname) {
    case "/best-seller":
      // src = "http://competoseoul.cdn1.cafe24.com/best-seller.png";
      // mobilesrc = "http://competoseoul.cdn1.cafe24.com/mo_best-seller.png";
      src = "/images/best-seller.png";
      mobilesrc = "/images/mo_best-seller.png";
      break;
    case "/bags":
      // src = "http://competoseoul.cdn1.cafe24.com/bag.png";
      // mobilesrc = "http://competoseoul.cdn1.cafe24.com/mo_bag.png";
      src = "/images/bag.png";
      mobilesrc = "/images/mo_bag.png";
      break;
    case "/wallets":
      // src = "http://competoseoul.cdn1.cafe24.com/wallet.png";
      // mobilesrc = "http://competoseoul.cdn1.cafe24.com/mo_wallet.png";
      src = "/images/wallet.png";
      mobilesrc = "/images/mo_wallet.png";
      break;
    case "/accessories":
      // src = "http://competoseoul.cdn1.cafe24.com/accessory.png";
      // mobilesrc = "http://competoseoul.cdn1.cafe24.com/mo_accessory.png";
      src = "/images/accessory.png";
      mobilesrc = "/images/mo_accessory.png";
      break;

    default:
      // src = "http://competoseoul.cdn1.cafe24.com/all-items.png";
      // mobilesrc = "http://competoseoul.cdn1.cafe24.com/mo_all-items.png";
      src = "/images/all-items.png";
      mobilesrc = "/images/mo_all-items.png";
      break;
  }
  return (
    <WrapperDiv>
      <div className="img">
        <img className="pc" alt="banner" src={src} />
        <img className="mobile" alt="banner" src={mobilesrc} />
      </div>
      <div className="text">
        <h5>{title}</h5>
      </div>
    </WrapperDiv>
  );
};

export default SubTitle;

export const WrapperDiv = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  display: block;
  text-align: center;

  .img {
    position: relative;
    width: 100%;
    height: fit-content;
  }

  img {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .text {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
  }

  .pc {
    display: block;
  }
  .mobile {
    display: none;
  }
  @media screen and (max-width: 700px) {
    .pc {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;
