import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { removeMember } from "../../store/memberSlice";

import axios from "axios";
import { Modal } from "../cart/Cart";

const MobileMenuList = ({ on, set }) => {
  const memberData = useSelector((state) => state.member);

  const dispatch = useDispatch();

  const onLogout = async () => {
    await axios
      .get("/api/logout")
      .then(() => dispatch(removeMember()))
      .then(() => (window.location.href = "/"));
  };
  return (
    <>
      <LeftToRightDiv className={!on ? "closed" : "opened"}>
        {memberData.id === 0 ? (
          <TopMenu>
            <p>로그인이 필요합니다.</p>
            <a href="/login">로그인</a>
          </TopMenu>
        ) : (
          <TopMenu>
            <p>{memberData.memberName}님 환영합니다.</p>
            <p
              onClick={onLogout}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              로그아웃
            </p>
          </TopMenu>
        )}
        <MenuList>
          <Link to={"/all-items"} onClick={() => set(false)}>
            <li>
              <h5>모든 아이템</h5>
            </li>
          </Link>
          <Link to={"/best-seller"} onClick={() => set(false)}>
            <li>
              <h5>베스트셀러</h5>
            </li>
          </Link>
          <Link to={"/bags"} onClick={() => set(false)}>
            <li>
              <h5>가방</h5>
            </li>
          </Link>
          <Link to={"/wallets"} onClick={() => set(false)}>
            <li>
              <h5>지갑</h5>
            </li>
          </Link>
          <Link to={"/accessories"} onClick={() => set(false)}>
            <li>
              <h5>악세서리</h5>
            </li>
          </Link>
        </MenuList>
        <BottomList>
          <Link to={"/about"} onClick={() => set(false)}>
            <li>브랜드</li>
          </Link>
          <Link to={"/mypage/inquiries"} onClick={() => set(false)}>
            <li>문의하기</li>
          </Link>
          {memberData.id === 0 ? (
            <></>
          ) : (
            <Link to={"/mypage"} onClick={() => set(false)}>
              <li>마이페이지</li>
            </Link>
          )}
          <Link to={"/?cart=on"} onClick={() => set(false)}>
            <li>장바구니</li>
          </Link>
        </BottomList>
        <Logo>
          <img
            alt="logo"
            src="/images/logo.svg"
            onClick={() => (window.location.href = "/")}
          />
        </Logo>
      </LeftToRightDiv>
      <Modal
        className={!on ? "closed" : "opened"}
        onClick={() => set(false)}
      ></Modal>
    </>
  );
};

export default MobileMenuList;

const LeftToRightDiv = styled.div`
  position: fixed;
  display: block;
  left: -100%;
  top: 0;
  bottom: 0;
  z-index: 100;

  box-shadow: 0 0 0;

  // transform: translateX(-100%);

  width: 25%;
  min-width: 486px;
  height: 100vh;

  background-color: white;
  transition: all 0.6s ease;

  &.opened {
    left: 0;
  }
  &.closed {
    left: -100%;
  }

  @media screen and (max-width: 700px) {
    min-width: auto;
    width: 78%;
  }
`;

const TopMenu = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: var(--main-color);
  color: white;

  padding: 25px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  @media screen and (max-width: 700px) {
    p,
    a {
      font-weight: 500;
      font-size: 11pt;
      text-underline-offset: 3px;
    }
  }
`;

const MenuList = styled.ul`
  position: relative;
  width: 100%;
  height: fit-content;

  padding: 35px 20px;

  display: grid;
  gap: 20px;

  @media screen and (max-width: 700px) {
    h5 {
      font-weight: bold;
      font-size: 16pt;
    }
  }
`;

const BottomList = styled.ul`
  position: relative;
  width: 100%;
  height: fit-content;

  padding: 0 20px;

  display: grid;
  gap: 16px;

  @media screen and (max-width: 700px) {
    li {
      font-weight: 600;
    }
  }
`;

export const Logo = styled.a`
  position: absolute;
  left: 20px;
  bottom: 30px;
  width: fit-content;
  height: fit-content;

  img {
    position: relative;
    width: auto;
    height: 10px;
  }
`;
