import React from "react";

import { Link } from "react-router-dom";

import styled from "styled-components";

const Banner = ({ data }) => {
  const banner1 = data[0];
  const banner2 = data[1];

  return (
    <GridDiv>
      {banner1 && (
        <Link to={banner1.url}>
          <ImgDiv>
            <img alt="banner" src={banner1.img} />
            <div>
              <p>{banner1.txt1}</p>
              <small>{banner1.txt2}</small>
            </div>
          </ImgDiv>
        </Link>
      )}
      {banner2 && (
        <Link to={banner2.url}>
          <ImgDiv color="white">
            <img alt="banner" src={banner2.img} />
            <div>
              <p>{banner2.txt1}</p>
              <small>{banner2.txt2}</small>
            </div>
          </ImgDiv>
        </Link>
      )}
    </GridDiv>
  );
};

export default Banner;

export const GridDiv = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;

  width: 100%;
  max-width: 100vw;
  height: calc(100dvh - 80px);

  &.end {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    height: fit-content;

    &.end {
      margin-bottom: 0;
    }
  }
`;

const ImgDiv = styled.div`
  position: relative;
  width: 100%;
  height: calc(100dvh - 80px);
  overflow: hidden;

  display: grid;
  justify-items: center;
  align-items: center;

  background-color: #f2f2f2;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  div {
    position: absolute;
    left: var(--wrapper-padding);
    bottom: var(--wrapper-padding);
    color: ${(props) => (props.color ? props.color : "var(--main-color)")};

    small {
      font-weight: 500;
    }
  }

  @media screen and (max-width: 700px) {
    max-width: 100vw;
    // height: calc((var(--vh, 1vh) * 100));
    height: calc((var(--dvh, 1dvh) * 100) - 80px);
    // height: calc(100vh - 80px);
    img {
      max-height: none;
      height: 100%;
    }
  }
`;

export const HomeWrapDiv = styled.div`
  margin-bottom: 80px;

  @media screen and (max-width: 700px) {
    margin-bottom: 0;
  }
`;

export const AbsoluteTxt = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  text-align: center;

  div {
    position: relative;
    width: 100%;
    height: fit-content;

    a {
      position: relative;
      width: fit-content;
      height: fit-content;
      background-color: white;

      p {
        position: relative;
        color: var(--main-color);
      }
    }
  }
`;
