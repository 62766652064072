import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Cart from "../cart/Cart";
import HeaderLine from "./HeaderLine";
import MobileMenuList from "./MobileMenuList";
import Search from "./Search";

const Header = () => {
  const memberData = useSelector((state) => state.member);
  const cartData = useSelector((state) => state.cart);

  const [searchParams] = useSearchParams();
  const query = searchParams.get("cart");

  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();

  const [cart, setCart] = useState(false);
  const [search, setSearch] = useState(false);
  const [MobileMenuOn, setMobileMenuOn] = useState(false);

  useEffect(() => {
    query && setCart(true);
  }, [query]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const body = document.body;
    if (MobileMenuOn || search || cart) {
      body.classList.add("is-hidden");
    } else {
      body.classList.remove("is-hidden");
    }

    return () => {
      body.classList.remove("is-hidden");
    };
  }, [MobileMenuOn, search, cart]);

  return (
    <>
      <HeaderWrapper>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            maxWidth: "100vw",
            overflowX: "hidden",
          }}
          className={isSticky ? "sticky" : ""}
        >
          <HeaderLine />
          <HeaderInner>
            <BurgerIcon onClick={() => setMobileMenuOn(true)}>
              <img
                alt="icon"
                src="/images/icon_burger.svg"
                width={69}
                height={29}
              />
            </BurgerIcon>
            <FlexWrapper>
              <Logo>
                <img
                  alt="logo"
                  src="/images/logo.svg"
                  onClick={() => {
                    navigate("/");
                    window.scrollTo(0, 0);
                    window.location.reload();
                  }}
                />
              </Logo>
              <FlexUl display="none">
                <Link to={"/best-seller"}>
                  <li>베스트셀러</li>
                </Link>
                <Link to={"/bags"}>
                  <li>가방</li>
                </Link>
                <Link to={"/wallets"}>
                  <li>지갑</li>
                </Link>
                <Link to={"/accessories"}>
                  <li>악세서리</li>
                </Link>
                <Link to={"/about"}>
                  <li>브랜드</li>
                </Link>
              </FlexUl>
            </FlexWrapper>
            <FlexUl property="end">
              <SearchInput>
                <img
                  alt="icon"
                  src="/images/icon_search.svg"
                  width={13}
                  height={13}
                />
                <input
                  type={"text"}
                  onFocus={() => setSearch(true)}
                  readOnly
                  placeholder="검색어를 입력하세요"
                />
              </SearchInput>
              <li className="mobile-visible" onClick={() => setSearch(true)}>
                <img
                  style={{ paddingTop: "5px" }}
                  alt="icon"
                  src="/images/icon_search.svg"
                  width={13}
                  height={13}
                />
              </li>
              <li className="mobile-hidden">
                {memberData.id === 0 ? (
                  <button onClick={() => (window.location.href = "/login")}>
                    <img
                      alt="icon"
                      src="/images/icon_login.svg"
                      width={14}
                      height={13}
                    />
                  </button>
                ) : (
                  <button onClick={() => (window.location.href = "/mypage")}>
                    <img
                      alt="icon"
                      src="/images/icon_login.svg"
                      width={14}
                      height={13}
                    />
                  </button>
                )}
              </li>
              <li>
                {cartData.length === 0 ? (
                  <button>
                    <img
                      alt="icon"
                      src="/images/icon_bucket_empty.svg"
                      width={14}
                      height={13}
                      onClick={() => setCart(true)}
                    />
                  </button>
                ) : (
                  <button>
                    <img
                      alt="icon"
                      src="/images/icon_bucket.svg"
                      width={14}
                      height={13}
                      onClick={() => setCart(true)}
                    />
                  </button>
                )}
              </li>
            </FlexUl>
          </HeaderInner>
        </div>
      </HeaderWrapper>
      <Cart on={cart} set={setCart} />
      <Search on={search} set={setSearch} />
      <MobileMenuList on={MobileMenuOn} set={setMobileMenuOn} />
    </>
  );
};

export default Header;

const HeaderWrapper = styled.header`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: fit-content;
  z-index: 3;
  background-color: #fff;

  & > div {
    animation: sticky2 0.3s both;
  }
  & > div.sticky {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 80px !important;
    animation: sticky 0.3s both;
  }
  @keyframes sticky {
    0% {
      top: 0px;
    }
    100% {
      top: -30px;
    }
  }

  @keyframes sticky2 {
    0% {
      top: -30px;
    }
    100% {
      top: 0px;
    }
  }
`;

const HeaderInner = styled.div`
  position: relative;
  // top: 30px;
  width: 100%;
  height: 50px;
  z-index: 4;
  padding: 0 var(--wrapper-padding);
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background: #fff;
  transition: all 0.3s;

  @media screen and (max-width: 1280px) {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    background: #fff;
  }
`;

const FlexWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 50px;
`;

const FlexUl = styled.ul`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${(props) => (props.property ? props.property : "start")};
  align-items: center;
  gap: 30px;

  img {
    width: 14px;
    height: auto;
  }

  @media screen and (max-width: 1280px) {
    display: ${(props) => (props.display ? props.display : "flex")};
    gap: 20px;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

export const Logo = styled.a`
  padding: 0;
  width: fit-content;
  display: inline-block;

  img {
    display: block;
    width: 192
    max-width: 192px;
    height: 19px;
  }

  @media screen and (max-width: 1280px) {
    width: 100%;

    img {
      width: auto;
      height: 14px;
      margin: 0 auto;
    }
  }
`;

const BurgerIcon = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  display: none;

  img {
    width: auto;
    height: 17px;
  }

  @media screen and (max-width: 1280px) {
    display: inline-block;
  }
`;

export const SearchInput = styled.div`
  position: relative;
  width: 249px;
  height: 33px;
  border: 1px solid #151414;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 3px;

  input {
    border: 0 solid white;
    font-size: var(--font-size-xs);
    font-weight: 400;
  }

  @media screen and (max-width: 1280px) {
    display: none;
  }
`;
