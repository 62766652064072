import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDaumPostcodePopup } from 'react-daum-postcode';

import {
    AddressGrid,
    CheckBox,
    CheckBoxAndLabel,
    CommonBtn,
    PaymentInput,
} from '../../../../../components/payment/ShipInfo';
import { UserInfoWrap } from '../../../../../components/user/UserComponent';
import axios from 'axios';

const MypageAddressDetail = () => {
    const params = useParams();
    const navigate = useNavigate();
    const memberData = useSelector((state) => state.member);

    const [data, setData] = useState('');

    const [sname, setSname] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [zonecode, setZonecode] = useState('');
    const [addressDetail, setaddressDetail] = useState('');
    const [baseAdr, setBaseAdr] = useState(true);

    const open = useDaumPostcodePopup('//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js');

    const handleClick = () => {
        open({ onComplete: completeHandler });
    };

    const completeHandler = (data) => {
        const { address, zonecode } = data;
        setZonecode(zonecode);
        setAddress(address);
    };

    params.addressId &&
        !data &&
        axios
            .get(`${process.env.REACT_APP_DOMAIN}/api/shipment/adr_get.php?memId=` + memberData.id + "&addrId=" + params.addressId)
            .then((res) => {
                if (res.data.result) {
                    setData(res.data.dto);
                } else {
                    alert(res.data.message );
                    navigate('/mypage/address');
                }
            })
            .catch((e) => alert(e));

    console.log(data);

    useEffect(() => {
        if (data) {
            setSname(data.sname);
            setName(data.recipient);
            setPhone(data.phone);
            setZonecode(data.zipCode);
            setAddress(data.address);
            setaddressDetail(data.adrDetail);
            setBaseAdr(data.baseAddress === 'Y' ? true : false);
        }
    }, [data]);

    const saveShipmentHandler = async () => {
        if ((!name, !phone, !address)) return false;

        await axios
            .post(`${process.env.REACT_APP_DOMAIN}/api/shipment/adr_save.php`, {
                sname: sname,
                recipient: name,
                phone: phone,
                zipCode: zonecode,
                address: address,
                adrDetail: addressDetail,
                baseAddress: baseAdr ? 'Y' : 'N',
                memberId: memberData.id,
            })
            .then((res) => {

                console.log( res );

                if (res.data.result === '0000') {
                    alert('정상적으로 등록되었습니다.');
                    window.location.reload();
                }
            })
            .catch((e) => alert('다시 시도해주세요\n' + e));
    };

    const updateShipmentHandler = async () => {
        if ((!name, !phone, !address)) return false;

        await axios
            .put(`${process.env.REACT_APP_DOMAIN}/api/shipment/adr.php`, {
                id: params.addressId,
                sname: sname,
                recipient: name,
                phone: phone,
                zipCode: zonecode,
                address: address,
                adrDetail: addressDetail,
                baseAddress: baseAdr ? 'Y' : 'N',
                memberId: memberData.id,
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    alert('정상적으로 수정되었습니다.');
                    navigate('/mypage/address');
                }
            });
    };

    return (
        <div>
            <p className="title">주소록</p>
            <UserInfoWrap>
                <p>배송지명</p>
                <PaymentInput
                    type="text"
                    placeholder="배송지"
                    value={sname}
                    onChange={(e) => setSname(e.target.value)}
                />
                <div style={{ height: '20px' }}></div>
                <p>수령인</p>
                <PaymentInput type="text" placeholder="이름" value={name} onChange={(e) => setName(e.target.value)} />
                <div style={{ height: '20px' }}></div>
                <p>휴대폰 번호</p>
                <PaymentInput
                    type="text"
                    placeholder="‘-’ 없이 숫자만 입력"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
                />
                <div style={{ height: '20px' }}></div>
                <p>배송지</p>
                <AddressGrid>
                    <PaymentInput id="address" type={'text'} value={address} disabled={true} />
                    <CommonBtn type="button" property="var(--main-color)" color="white" onClick={handleClick}>
                        주소 찾기
                    </CommonBtn>
                </AddressGrid>
                <PaymentInput
                    type="text"
                    placeholder="상세주소"
                    value={addressDetail}
                    onChange={(e) => setaddressDetail(e.target.value)}
                />
                <CheckBoxAndLabel style={{ marginTop: '20px' }} onClick={() => setBaseAdr(!baseAdr)}>
                    <CheckBox type="checkbox" name="privacy" id="privacy" checked={baseAdr} />

                    <label htmlFor="privacy">
                        <label className="checkbox"></label>기본 배송지로 지정
                    </label>
                </CheckBoxAndLabel>
                <div style={{ height: '40px' }}></div>
                {data ? (
                    <CommonBtn type="button" property="var(--main-color)" color="white" onClick={updateShipmentHandler}>
                        수정
                    </CommonBtn>
                ) : (
                    <CommonBtn type="button" property="var(--main-color)" color="white" onClick={saveShipmentHandler}>
                        저장
                    </CommonBtn>
                )}
            </UserInfoWrap>
        </div>
    );
};

export default MypageAddressDetail;
